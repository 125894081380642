<template>
  <div class="container">
    <div class="main">
      <div v-if="image" class="top_image">
        <img :src="image" />
      </div>
      <div v-if="lead" class="statement">
        <p>{{ lead }}</p>
      </div>
      <div v-if="btns && btns.length">
        <div v-for="(btn, index) in btns" :key="index" class="button_area">
          <component
            :is="btn.url ? 'a' : 'button'"
            :href="btn.url"
            target="_blank"
            @click="onClick(btn)"
          >
            {{ btn.label }}
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import configData from '@/assets/data/config'

const { filename, lead: _lead, btns: _btns } = reactive(configData || {})
const image = ref(require(`@/assets/img/${filename}`))
const lead = ref(_lead)
const btns = reactive(_btns)

const getShareUrl = str => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(str)}`
}

const onClick = data => {
  const { shareText, url } = data;
  !url && shareText && window.open(getShareUrl(shareText))
}

btns.map(e => {
  e.shareText && (e.url = getShareUrl(e.shareText))
  return e
})
</script>
