import { createApp } from 'vue'
import VueGtag from 'vue-gtag-next'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/tailwind.css'
import './assets/css/main.scss';

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    property: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASURE_ID },
  }, router)
  .mount('#app')
