<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style scoped>
.app {
  background-color: #fff;
}
@media (prefers-color-scheme: dark) {
  /* ダークモードの時 */
  .app {
    background-color: #222;
  }
}
</style>
