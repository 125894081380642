/**
 * タブも反映されるので不要でしたらタブは削除ください
 */
export default {
  filename: "main.jpeg",

  lead: `新時代を担う高校生ヒーロー
＜ミズ・マーベル＞ことカマラ・カーン

彼女の“伸びしろ”は無限大⁉️
新米ヒーロー＜ミズ・マーベル＞の気になる、または応援したくなるポイントは❓`,

  btns: [
    {
      label: `アベンジャーズオタクなところ`,
      shareText: `『ミズ・マーベル』の気になるポイントは【アベンジャーズオタクなところ】

【こちらに、その理由や他本作に関するコメントを入れてください】

#ミズマーベル感想キャンペーン`,
    },
    {
      label: `妄想力があるところ`,
      shareText: `『ミズ・マーベル』の気になるポイントは【妄想力があるところ】

【こちらに、その理由や他本作に関するコメントを入れてください】

#ミズマーベル感想キャンペーン`,
    },
    {
      label: `これまでのヒーローと違うタイプ`,
      shareText: `『ミズ・マーベル』の気になるポイントは【これまでのヒーローと違うタイプ】

【こちらに、その理由や他本作に関するコメントを入れてください】

#ミズマーベル感想キャンペーン`,
    },
    {
      label: `普通な高校生であるところ`,
      shareText: `『ミズ・マーベル』の気になるポイントは【普通な高校生であるところ】

【こちらに、その理由や他本作に関するコメントを入れてください】

#ミズマーベル感想キャンペーン`,
    },
  ],
};
